<template>
  <div class="dialog">
    <div class="dialog-header">
      <div class="row">
        <div class="col-8">
          <p class="dialog-title text-uppercase mb-0">Report Content?</p>
        </div>
        <div class="col-4">
          <img class="dialog-close-button"
               src="../../assets/images/close.png"
               alt="close-button"
               @click="$emit('close')" />
        </div>
      </div>
    </div>

    <div class="dialog-content"
         style="overflow-y: auto;"
         v-bind:style="{height: contentHeightOverride}">
        <div class="row">
          <div class="col-4">
            <!-- Content Thumbnail -->
            <img class="ucc-thumbnail"
                   :src="media.thumbnail"
                   alt="created-content"
                   v-if="media.thumbnail != ''" />
            <img class="ucc-thumbnail-default"
                  src="../../assets/images/h3_logo.png"
                  alt="created-content"
                  v-else />
          </div>
          <div class="col-8">
            <form>
              <!-- Checkbox -->
              <b-form-group label="Please tell us why">
                <b-form-checkbox-group id="categoryOptions"
                                       v-model="selectedCategory"
                                       :options="categoryOptions"
                                       name="categoryOptions"
                                       stacked>
                </b-form-checkbox-group>
              </b-form-group>

              <!-- Text Area -->
              <b-form-textarea class="mt-2"
                               id="textarea-small"
                               v-model="otherDetails"
                               placeholder="Details"
                               size="sm"
                               :state="!$v.otherDetails.$error"
                               @input="$v.otherDetails.$touch()"
                               @keyup.native="updateTextAreaCounter">
              </b-form-textarea>
              <b-form-invalid-feedback v-if="$v.otherDetails.$error">
                <span v-if="!$v.otherDetails.required">
                  Details is a required field.
                </span>
              </b-form-invalid-feedback>
              <p class="small text-right">
                {{ remainingChars }} remaining characters
              </p>

              <!-- Buttons -->
              <div class="text-right mt-3">
                <b-button class="btn btn-success btn-h3-small"
                          type="button"
                          @click="report"
                          :disabled="remainingChars < 0 || $v.$invalid">
                  Submit
                </b-button>
                &nbsp;
                <b-button class="btn btn-danger btn-h3-small"
                          @click="$emit('close')">
                  Cancel
                </b-button>
              </div>
            </form>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
  import BreakpointMixin from '@/mixins/BreakpointMixin';
  import { mapGetters } from 'vuex';
  import { required } from 'vuelidate/lib/validators';

  export default {
    name   : 'ReportContent',
    mixins : [
      BreakpointMixin,
    ],
    props : {
      mediaData : Object,
      type      : String,
    },
    data() {
      return {
        selectedCategory : null,
        otherDetails     : '',
        maxChars         : 80,
        remainingChars   : 80,
        ownerData        : null,
      }
    },
    validations() {
      if (_.indexOf(this.selectedCategory, this.othersId) < 0) {
        return {
          selectedCategory : { required },
          otherDetails     : {},
        }
      }

      return {
        selectedCategory : { required },
        otherDetails     : {
          required,
          allowedChars() {
            return this.otherDetails.length <= 80;
          },
        },
      }
    },
    watch : {
      selectedCategory(value) {
        const check = _.indexOf(value, this.othersId);
        if (check < 0)
          this.$v.otherDetails.$reset(); // reset text area validation
      },
    },
    computed : {
      ...mapGetters({
        'issueCategories' : 'contentReporting/issueCategories',
      }),
      media() {
        let mediaData = {};

        if (this.mediaData.item) {
          mediaData = {
            thumbnail  : this.mediaData.item.thumbnail,
            mediaId    : this.mediaData.item.id,
            ownerId    : this.mediaData.item.user_id,
            guardianId : this.mediaData.item.guardian_id,
          };
        } else {
          mediaData = {
            thumbnail : (this.type === 'image') ?
              this.mediaData.url : this.mediaData.thumbnail,
            mediaId : (this.mediaData.id) ?
              this.mediaData.id : this.mediaData.ucc_id,
            ownerId : (this.mediaData.userId) ?
              this.mediaData.userId : this.mediaData.user_id,
            guardianId : (this.mediaData.guardianId) ?
              this.mediaData.guardianId : this.mediaData.guardian_id,
          };
        }

        return mediaData;
      },
      categoryOptions() {
        const options = [];

        this.issueCategories.data.forEach(element => {
          options.push({
            value : element.ucc_issues_category_id,
            text  : element.category_name,
          });
        });

        return options;
      },
      othersId() {
        let othersId = 0;

        this.issueCategories.data.forEach(element => {
          if (element.category_name.includes('Others'))
            othersId = element.ucc_issues_category_id;
        });

        return othersId;
      },
    },
    methods : {

      /**
       * Report Content
       */
      report() {
        this.$http.post('api/issues/add', {
          issueCategoryType : this.selectedCategory,
          guardianId        : this.media.guardianId,
          uccId             : this.media.mediaId,
          contentOwnerId    : this.media.ownerId,
          reporterId        : this.$store.getters['user/user'].user_id,
          reason            : this.otherDetails,
        }).then(() => {
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : 'Reported content successfully!',
          });
          this.$emit('close');
        }).catch(response => {
          if (response.message.indexOf('409') > -1) {
            this.$notify({
              group : 'notif',
              type  : 'success',
              title : 'Success!',
              text  : 'Reported content successfully!',
            });
            this.$emit('close');
          } else {
            this.$notify({
              group : 'notif',
              type  : 'error',
              title : 'Failed!',
              text  : 'Oops! Something went wrong.',
            });
          }
        });
      },

      /**
       * Update Text Area Counter
       */
      updateTextAreaCounter() {
        this.remainingChars = this.maxChars - this.otherDetails.length;
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/modals/report-content";
</style>